<template>
  <div class="compilers_right_block">
    <div class="filter_input">
      <img src="../../assets/images/homePage/searchIcon.svg"/>
      <input v-model="searchText" type="text"/>
      <img src="../../assets/images/homePage/x.svg" @click="clearInput"/>
    </div>
    <div class="filter_letters"> 
      <FilterItem
        v-for="item in filterItems"
        :key="item.id"
        :active="item.active"
        @onFilterClick="toggleActiveFilter(item.id)"
      >{{ item.title }}
      </FilterItem
      >
    </div>
    <div :style="{'height': (17*links.length + 'px') }" class="languages">
      <div v-for="item in links" :key="item.language">
        <router-link :to="item.link">
          <span @click="$emit('closeCodeModal')">{{ item.displayName }}</span>
        </router-link>
      </div>
    </div>

    <MyButton
      class="right_block_button"
      @openCommentBox="$emit('openCommentBox')"
    >Recommend a language
    </MyButton
    >
  </div>
</template>
<script>
import FilterItem from './FilterItem.vue'
import MyButton from '../MyButton.vue'
import dataLanguages from '../../dataLanguages.json'
import { LANGS_AND_LINKS } from '../../assets/javascript/languages-and-links'

export default {
  name: 'compilers_right_block',
  components: {
    FilterItem,
    MyButton
  },
  data () {
    return {
      searchText: '',
      filterItems: [
        {
          id: 'popular',
          title: 'Popular'
        },
        {
          id: 'A-E',
          title: 'A-E'
        },
        {
          id: 'F-K',
          title: 'F-K'
        },
        {
          id: 'L-Q',
          title: 'L-Q'
        },
        {
          id: 'R-Z',
          title: 'R-Z'
        },
        {
          id: 'terminal',
          title: 'Terminal'
        }
      ],
      languages: LANGS_AND_LINKS.sort((a, b) => a.displayName > b.displayName ? 1 : -1),
      filterBy: 'popular'
    }
  },
  created () {
    this.toggleActiveFilter('popular')
  },
  methods: {
    toggleActiveFilter (id) {
      this.filterBy = id
      this.filterItems = this.filterItems.map(item => {
        if (item.id === id) {
          return { ...item, active: item.id }
        } else {
          return { ...item, active: '' }
        }
      })
    },
    clearInput () {
      this.searchText = ''
      this.filterBy = ''
      this.filterItems = this.filterItems.map(item => {
        return { ...item, active: false }
      })
    }
  },
  watch: {
    filterBy () {
      this.searchText = ''
    }
  },
  computed: {
    // filteredArray () {
    //   if (this.searchText) {
    //     this.filterBy = ''
    //     this.filterItems = this.filterItems.map(item => {
    //       return { ...item, active: false }
    //     })
    //     return this.languages.filter(language => {
    //       return language.title
    //         .toLowerCase()
    //         .includes(this.searchText.toLowerCase())
    //     })
    //   } else if (this.filterBy === 'popular' || this.filterBy === 'terminal') {
    //     return [...this.languages].filter(e => e[this.filterBy]).sort((a, b) => a.title > b.title ? 1 : -1)
    //   } else if (this.filterBy == '') {
    //     return this.languages.sort()
    //   } else {
    //     return this.languages.filter(item => {
    //       const firstChar = item.title.charAt(0).toUpperCase()
    //       return firstChar >= this.filterBy[0] && firstChar <= this.filterBy[2]
    //     }).sort()
    //   }
    // },
    links: function () { 
     if(this.searchText){
        let links = this.$_.filter(this.languages, o => {
        return o.searchTerm.includes(this.searchText.toLowerCase())
      }) 
      return links
      }else if (this.filterBy === 'popular' || this.filterBy === 'terminal') {
        return [...this.languages].filter(e => e[this.filterBy]).sort((a, b) => {  
          return a.displayName > b.displayName ? 1 : -1
        })
      } else if (this.filterBy == '') {
        return this.languages.sort()
      } else {
        return this.languages.filter(item => {
          const firstChar = item.displayName.charAt(0).toUpperCase()
          return firstChar >= this.filterBy[0] && firstChar <= this.filterBy[2]
        }).sort()
      }
      
    }
  }
}
</script>
<style lang="scss" scoped>
.compilers_right_block {
  padding: 15px 0 15px 30px;
  position: relative; 

  .right_block_button {
    font-family: "Inter";
    font-style: normal;
    font-weight: 380;
    font-size: 14px;
    line-height: 17px;
    background-color: #fd5a0d;
    color: #ffffff;
    cursor: pointer;
    display: flex;
    margin: auto;
  }

  .filter_input {
    display: flex;
    background: #f8f8f8;
    border-radius: 20px;
    align-items: center;
    padding: 10px 16px;
    gap: 10px;

    input {
      border: none;
      background-color: unset;
      outline: none;
      width: 100%;
    }
  }

  .filter_letters {
    display: flex;
    margin-top: 15px;
    justify-content: space-between;
    gap: 10px 30px;
    flex-wrap: wrap;
    justify-content: center;

    span {
      padding: 7px 10px;
      background: #f8f8f8;
      border-radius: 15px;
      color: #4b4b5a;
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      letter-spacing: -0.01em;
      cursor: pointer;
    }
  }

  .languages {
    display: flex;
    flex-direction: column;
    margin-top: 34px;
    min-height: 400px;
    flex-wrap: wrap;
    row-gap: 18px;
    column-gap: 25px;
    padding-bottom: 5px;

    div {
      span {
        font-family: "Inter";
        font-style: normal;
        font-size: 15px;
        line-height: 18px;
        font-weight: 500;
        color: #000000;
      }
    }
  }
}

@media only screen and (max-width: 1040px) {
  .compilers_right_block {
    padding: 0;

    > p {
      bottom: -90px;
      left: 15%;
    }

    .filter_letters {
      gap: 8px 24px;

      span {
        font-size: 13px;
        line-height: 16px;
      }
    }

    .languages {
      div {
        gap: 10px;

        span {
          font-size: 14px;
        }
      }
    }
  }
}

@media only screen and (max-width: 769px) {
  .compilers_right_block {
    width: 100%;

    .languages {
      min-height: 100px;
    }

    .right_block_button {
      margin-top: 30px;
    }
  }

}

@media only screen and (max-width: 830px) {
  .compilers_right_block {
    padding-top: 20px;
  }
}

@media only screen and (max-width: 630px) {
  .compilers_right_block {
    padding-top: 0px;
    margin-top: 42px;

    > p {
      bottom: 4px;
      margin: auto;
      left: 0;
      right: 0;
      width: fit-content;
    }

    .languages {
      column-gap: 10px;

      div {
        span {
          font-size: 12px;
        }
      }
    }
  }
}

@media only screen and (max-width: 375px) {
  .compilers_right_block {
    margin-top: 60px;

    .right_block_button {
      font-size: 12px;
      padding: 7px 11px;
    }

    > p {
      bottom: 0;
    }

    .filter_input {
      margin-left: 28px;
      margin-right: 28px;
    }

    .filter_letters {
      margin-top: 15px;
      gap: 15px;

      span {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
      }
    }

  }
}

/* scroll styles */

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: grey;
}
</style>
