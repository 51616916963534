export const LANGS_AND_LINKS = [
  { searchTerm: 'java', link: '/online-java-compiler/', displayName: 'Java',popular: true },
  { searchTerm: 'java', link: '/online-java-compiler-ide/', displayName: 'Java (Advanced)',popular: true },
  { searchTerm: 'html javascript css react angular', link: '/html-css-javascript-online-editor/', displayName: 'HTML/CSS/Javascript',other: true },
  { searchTerm: 'c', link: '/c-online-compiler/', displayName: 'C',popular: true },
  { searchTerm: 'c++ cpp', link: '/online-compiler-c++/', displayName: 'C++',popular: true },
  { searchTerm: 'c++ 14 cpp14 c++14', link: '/online-compiler-c++14/', displayName: 'C++ 14',other:true },
  { searchTerm: 'c++ 17 cpp17 c++17', link: '/online-compiler-c++17/', displayName: 'C++ 17',popular: true },
  { searchTerm: 'c 99 c99', link: '/compile-c99-online/', displayName: 'C99',other: true },
  { searchTerm: 'c sharp c# csharp', link: '/compile-c-sharp-online/', displayName: 'C#',popular: true },
  { searchTerm: 'php', link: '/php-online-editor/', displayName: 'PHP',popular: true },
  { searchTerm: 'perl', link: '/execute-perl-online/', displayName: 'Perl',popular: true },
  { searchTerm: 'ruby', link: '/execute-ruby-online/', displayName: 'Ruby',other: true },

  { searchTerm: 'python 2', link: '/python-programming-online/', displayName: 'Python2',popular: true },
  { searchTerm: 'python 3', link: '/python3-programming-online/', displayName: 'Python3',popular: true },
  { searchTerm: 'sql', link: '/execute-sql-online/', displayName: 'SQL',popular: true },
  { searchTerm: 'scala', link: '/compile-scala-online/', displayName: 'Scala',popular: true },

  { searchTerm: 'vb.net', link: '/compile-vb-dot-net-online/', displayName: 'VB.Net',other: true },
  { searchTerm: 'pascal', link: '/execute-pascal-online/', displayName: 'Pascal',popular: true },
  { searchTerm: 'haskell', link: '/execute-haskell-online/', displayName: 'Haskell',other: true },
  { searchTerm: 'go', link: '/execute-go-online/', displayName: 'Go',other: true },

  { searchTerm: 'swift', link: '/execute-swift-online/', displayName: 'Swift',popular: true },
  { searchTerm: 'objective c', link: '/execute-objective-c-online/', displayName: 'Objective-C',other: true },
  { searchTerm: 'groovy', link: '/execute-groovy-online/', displayName: 'Groovy',popular: true },
  { searchTerm: 'fortran', link: '/execute-fortran-online/', displayName: 'Fortran',other: true },

  { searchTerm: 'brainfuck', link: '/execute-brainfuck-online/', displayName: 'Brainf**k',other: true },
  { searchTerm: 'hack', link: '/execute-hack-online/', displayName: 'Hack',other: true },
  { searchTerm: 'tcl', link: '/execute-tcl-online/', displayName: 'TCL',other: true },
  { searchTerm: 'lua', link: '/execute-lua-online/', displayName: 'Lua',popular: true },

  { searchTerm: 'rust', link: '/execute-rust-online/', displayName: 'Rust',other: true },
  { searchTerm: 'r', link: '/execute-r-online/', displayName: 'R',other: true },
  { searchTerm: 'ada', link: '/execute-ada-online/', displayName: 'Ada',other: true },
  { searchTerm: 'd', link: '/execute-d-online/', displayName: 'D',other: true },

  { searchTerm: 'dart', link: '/execute-dart-online/', displayName: 'Dart',other: true },
  { searchTerm: 'yabasic', link: '/execute-yabasic-online/', displayName: 'YaBasic',other: true },
  { searchTerm: 'freebasic', link: '/execute-freebasic-online/', displayName: 'Free Basic',other: true },
  { searchTerm: 'clojure', link: '/execute-clojure-online/', displayName: 'Clojure',other: true },

  { searchTerm: 'verilog', link: '/execute-verilog-online/', displayName: 'Verilog',other: true },
  { searchTerm: 'nodejs', link: '/execute-nodejs-online/', displayName: 'NodeJS',popular: true },
  { searchTerm: 'scheme', link: '/execute-scheme-online/', displayName: 'Scheme',other: true },
  { searchTerm: 'forth', link: '/execute-forth-online/', displayName: 'Forth',other: true },

  { searchTerm: 'prolog', link: '/execute-prolog-online/', displayName: 'Prolog',other: true },
  { searchTerm: 'bash', link: '/test-bash-shell-script-online/', displayName: 'Bash',other: true },
  { searchTerm: 'cobol', link: '/execute-cobol-online/', displayName: 'COBOL',popular: true },
  { searchTerm: 'octave matlab', link: '/execute-octave-matlab-online/', displayName: 'OCTAVE/Matlab',popular:true },
  { searchTerm: 'icon', link: '/compile-icon-online/', displayName: 'Icon',other:true },
  { searchTerm: 'coffeescript', link: '/compile-coffeescript-online/', displayName: 'CoffeeScript',other:true },

  { searchTerm: 'assembler gcc assembly', link: '/compile-assembler-gcc-online/', displayName: 'Assembler (GCC)',other:true },
  { searchTerm: 'f# fsharp', link: '/compile-fsharp-online/', displayName: 'F#',other:true },
  { searchTerm: 'assembler nasm assembly', link: '/compile-assembler-nasm-online/', displayName: 'Assembler (NASM)',popular:true },
  { searchTerm: 'intercal', link: '/compile-intercal-online/', displayName: 'Intercal',other:true },

  { searchTerm: 'nemerle', link: '/compile-nemerle-online/', displayName: 'Nemerle',other:true },
  { searchTerm: 'ocaml', link: '/compile-ocaml-online/', displayName: 'Ocaml',other:true },

  { searchTerm: 'unlambda', link: '/execute-unlambda-online/', displayName: 'Unlambda',other:true },
  { searchTerm: 'picolisp', link: '/execute-picolisp-online/', displayName: 'Picolisp',other:true },
  { searchTerm: 'clisp', link: '/execute-clisp-online/', displayName: 'CLISP',other:true },
  { searchTerm: 'elixir', link: '/execute-elixir-online/', displayName: 'Elixir',other:true },

  { searchTerm: 'spidermonkey javascript js', link: '/execute-spidermonkey-online/', displayName: 'SpiderMonkey',other:true },
  { searchTerm: 'rhinojs javascript', link: '/execute-rhino-online/', displayName: 'Rhino JS',other:true },
  { searchTerm: 'bc', link: '/execute-bc-online/', displayName: 'BC',other:true },
  { searchTerm: 'nim', link: '/execute-nim-online/', displayName: 'Nim',other:true },

  { searchTerm: 'factor', link: '/execute-factor-online/', displayName: 'Factor',other:true },
  { searchTerm: 'falcon', link: '/execute-falcon-online/', displayName: 'Falcon',other:true },
  { searchTerm: 'fantom', link: '/execute-fantom-online/', displayName: 'Fantom',other:true },
  { searchTerm: 'pike', link: '/execute-pike-online/', displayName: 'Pike',other:true },
  { searchTerm: 'smalltalk', link: '/execute-smalltalk-online/', displayName: 'SmallTalk',other:true },

  { searchTerm: 'kotlin', link: '/compile-kotlin-online/', displayName: 'Kotlin',other:true },
  { searchTerm: 'lolcode', link: '/execute-lolcode-online/', displayName: 'LOLCODE',other:true },
  { searchTerm: 'racket', link: '/execute-racket-online/', displayName: 'racket',other:true },
  { searchTerm: 'oz-mozart', link: '/compile-oz-mozart-online/', displayName: 'OZ Mozart',other:true },
  { searchTerm: 'whitespace', link: '/execute-whitespace-online/', displayName: 'Whitespace',other:true },
  { searchTerm: 'erlang', link: '/execute-erlang-online/', displayName: 'Erlang',other:true },
  { searchTerm: 'j language', link: '/execute-jlanguage-online/', displayName: 'J Language',other:true },

  { searchTerm: 'java jbang', link: '/try-jbang/', displayName: 'JBang',other:true },
  { searchTerm: 'fasm assembly assembler', link: '/execute-fasm-online/', displayName: 'Assembler (FASM)',other:true },
  { searchTerm: 'awk', link: '/execute-awk-online/', displayName: 'AWK',other:true },
  { searchTerm: 'algol68', link: '/execute-algol68-online/', displayName: 'Algol 68',other:true },
  { searchTerm: 'befunge cfunge', link: '/execute-befunge-online/', displayName: 'Befunge',other:true },
  { searchTerm: 'haxe', link: '/execute-haxe-online/', displayName: 'Haxe',other:true },
  { searchTerm: 'blockly', link: '/blockly-online/', displayName: 'Blockly',other:true },

  { searchTerm: 'mysql', link: '/online-mysql-terminal/', displayName: 'MySQL',terminal:true },
  { searchTerm: 'mongodb', link: '/online-mongodb-terminal/', displayName: 'mongoDB',terminal:true }
]
