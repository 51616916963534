<template>
  <div class="compilers_left_block">
    <TitleText :titleText="compilersTitle"/>
    <div class="compilers_info_block">
      <CompilersInfo v-for="item in compilesInfo" :key="item.id" :info="item"/>
    </div>
  </div>
</template>
<script>
import CompilersInfo from './CompilersInfo.vue'
import TitleText from '../TitleText.vue'

export default {
  name: 'compilers_left_block',
  data () {
    return {
      compilesInfo: [
        {
          id: 1,
          img: 'compiler1.svg',
          title: 'Write, run and test with our online IDEs within minutes'
        },
        {
          id: 2,
          img: 'compiler2.svg',
          title: 'Supports 76+ languages and  2 databases'
        },
        {
          id: 3,
          img: 'compiler3.svg',
          title: 'Real-time collaboration with  colleagues, peers, and mentors'
        },
        {
          id: 4,
          img: 'compiler4.svg',
          title: 'Instantly share your code with anyone'
        }
      ],
      compilersTitle: {
        title: 'Online Compilers and Editors',
        text: 'Developers and Students'
      }
    }
  },

  components: {
    CompilersInfo,
    TitleText
  }
}
</script>
<style lang="scss" scoped>
.compilers_left_block {
  width: 50%;

  .compilers_info_block {
    margin-top: 15px;
    width: 83%;
  }
}

@media only screen and (max-width: 1040px) {
  .compilers_left_block {
    .compilers_info_block {
      margin-top: 10px;
      width: 100%;
    }
  }
}

@media only screen and (max-width: 769px) {
  .compilers_left_block {
    width: 100%;
  }
}

@media only screen and (max-width: 375px) {
  .compilers_left_block {
    .compilers_info_block {
      margin-top: 40px;
      width: 100%;
    }
  }
}
</style>
