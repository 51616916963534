<template>
  <div class="compilers_info">
    <div class="compilers_img">
      <img :src="`${require('../../assets/images/homePage/' + info.img)}`" />
    </div>

    <div class="compilers_text">
      <p>{{ info.title }}</p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'compilers-info',
  props: {
    info: {
      type: Object,
      require: true
    }
  }
}
</script>
<style lang="scss" scoped>
.compilers_info {
  .compilers_img {
    display: flex;
    background: #f8f8f8;
    border-radius: 20px;
    padding: 25px;
    img {
      width: 50px;
    }
  }
  display: flex;
  align-items: center;
  gap: 42px;
  margin-top: 45px;
  .compilers_text {
    align-items: center;
    padding-right: 10%;
    p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 141%;
      letter-spacing: 0.01em;
      color: #000000;
    }
  }
}
@media only screen and (max-width: 1040px) {
  .compilers_info {
    gap: 35px;
    margin-top: 25px;
    .compilers_text {
      align-items: center;
      padding-right: 0;
      p {
        font-size: 16px;
      }
    }
  }
}
@media only screen and (max-width: 830px) {
  .compilers_info {
    gap: 20px;
    margin-top: 22px;
    .compilers_text {
      p {
        font-size: 14px;
      }
    }
    .compilers_img {
      padding: 20px;
    }
  }
}
@media only screen and (max-width: 375px) {
  .compilers_info {
    gap: 26px;
    margin-top: 20px;
    .compilers_img {
      border-radius: 10px;
      padding: 10px;
      align-items: flex-start;
      img {
        width: 24px;
      }
    }
    .compilers_text {
      align-items: center;
      padding-right: 0;
      p {
        font-size: 14px;
        line-height: 141%;
        font-weight: 500;
      }
    }
  }
}
</style>
