<template>
  <span :class="{ isActive: active }" @click="handleClick">
    <slot></slot>
    {{ link }}
  </span>
</template>
<script>
export default {
  name: 'filter_item',
  props: {
    link: {
      type: String,
      Object
    },
    active: {
      required: true
    }
  },
  methods: {
    handleClick () {
      this.$emit('onFilterClick')
    }
  }
}
</script>
<style lang="scss" scoped>
span {
  padding: 7px 10px;
  background: #f8f8f8;
  border-radius: 15px;
  color: #4b4b5a;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: -0.01em;
}
.isActive {
  background: #4b4b5a !important;
  color: #f3f3f3 !important;
}
</style>
